@use "sass:color";
@use "sass:map";
@use "sass:string";
@use "_colors";

// Icons
.bi {
  pointer-events: none;
  fill: currentColor;
}

// Layout
:root {
  --sidebar-xs-width: calc(2em + 40px);
  --sidebar-md-width: 220px;
}

main {
  min-height: 100vh;
}
.sidebar {
  width: var(--sidebar-md-width);
  z-index: 1000;
  transition: width ease-in-out 200ms;
  & + div {
    margin-left: var(--sidebar-md-width);
    & .sidebar {
      width: calc(var(--sidebar-md-width) + 4px);
      margin-left: var(--sidebar-md-width);
      z-index: 999;
      padding-right: 4px;
    }
  }
  #slide {
    margin-right: -16px;
    button {
      width: 32px;
      height: 64px;
      border-radius: 16px;
      transition: all ease-in-out 200ms;
      border: none;
      &:hover {
        background-color: colors.$brand-darker !important;
      }
    }
  }
}
body.in header.sidebar {
  width: 0px;
  & + div {
    margin-left: 0px;
    & .sidebar {
      margin-left: 0px;
    }
  }
  #slide button {
    width: 16px;
    border-radius: 0px 16px 16px 0px;
    svg {
      transform: rotate(180deg);
      transition: transform ease-in-out 200ms;
    }
  }
}
body.over header.sidebar {
  width: var(--sidebar-md-width);
  #slide button {
    width: 32px;
    border-radius: 16px;
  }
}
body.in.over header.sidebar {
  #slide button {
    width: 32px;
    border-radius: 16px;
  }
}

@media (max-width: 767px) {
  .sidebar {
    width: var(--sidebar-xs-width);
    & + div {
      margin-left: var(--sidebar-xs-width);
      .sidebar {
        margin-left: 0;
        position: relative;
        width: auto;
        height: auto !important;
        & + div {
          margin-left: 0;
        }
      }
    }
    #slide {
      margin-right: -16px;
    }
  }
  body.in header.sidebar {
    width: 0px;
    & + div {
      margin-left: 0px;
    }
  }
  body.over header.sidebar {
    width: var(--sidebar-xs-width);
  }
}

// Combining form elements when using floating form labels
.form-group {
  .form-floating:not(.collapse:not(.show)) {
    .form-control,
    .form-select,
    .choices .choices__inner {
      border-radius: 0;
      border-color: colors.$mid;
      &:focus {
        z-index: 2;
        position: relative;
        & + label {
          z-index: 3;
        }
      }
    }
    &:not(:last-of-type),
    &:not(.last) {
      margin-bottom: -1px;
    }
    &:first-of-type:not(.last),
    &.first {
      input,
      select,
      textarea,
      .choices__inner {
        &:not(.choices__input) {
          border-top-left-radius: 0.25rem;
          border-top-right-radius: 0.25rem;
        }
      }
    }
    &:last-of-type:not(.first),
    &.last {
      input,
      select,
      textarea,
      .choices__inner {
        &:not(.choices__input) {
          border-bottom-left-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
      }
    }
  }
}

// Fix for required asterick in forms
.required abbr {
  text-decoration: none;
  color: colors.$danger;
}

.required-field-error {
  border: 5px solid colors.$danger !important;
}

table.fixed {
  table-layout: fixed;
}

table.transparent { 
  --bs-table-bg: transparent !important;
}

.display-form-error {
  &.form-group-invalid {
    .form-control,
    .form-select {
      border-color: colors.$danger;

      &:focus {
        border-color: colors.$danger;
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
      }
    }

    .invalid-feedback {
      display: block;
    }
  }
}

// Fix for bootstrap floating form select options
.form-floating {
  &.show-placeholder > label {
    color: colors.$mid;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  }
  > label {
    color: colors.$mid;
    white-space: nowrap;
  }
  > .form-control:focus ~ label,
  > .form-control:not(:placeholder-shown) ~ label,
  > .form-control-plaintext ~ label,
  > .form-select ~ label {
    opacity: 1;
  }
  .form-select {
    padding-left: 0.75rem !important;
    padding-top: 1.25rem !important;
    padding-bottom: 0px !important;
  }

  &.opened {
    > label {
      z-index: 3 !important;
      transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    }
  }
  // > .form-control-lg,
  // > .form-select-lg {
  //   padding-top: 1.625rem !important;
  //   padding-bottom: 0.325rem !important;
  // }
  // .choices {
  //   + label {
  //     z-index: 2;
  //     transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  //   }
  //   // &.open {
  //   //   + label {
  //   //     z-index: 10002;
  //   //   }
  //   //   .selectr-selected {
  //   //     border-color: $primary;
  //   //     outline: 0;
  //   //     z-index: 10001;
  //   //     &:not([aria-expanded="true"]) {
  //   //       box-shadow: 0 0 0 0.25rem rgba($primary, 0.25);
  //   //     }
  //   //   }
  //   // }
  // }

  // .selectr-optgroup--label {
  //   margin-top: 0px;
  //   text-transform: uppercase;
  //   font-size: 0.75rem;
  //   font-weight: normal;
  //   color: $mid;
  // }

  // &.no-options {
  //   .selectr-container + label {
  //     opacity: 0.65;
  //     transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  //   }
  //   .selectr-placeholder {
  //     color: $mid;
  //   }
  // }

  // .choices__inner {
  //   min-height: calc(3.5rem + 2px);
  //   padding-top: 1.625rem !important;
  //   padding-bottom: 0.325rem !important;
  //   padding-left: 0.75rem !important;
  //   font-size: 1.25rem;
  // .selectr-tags {
  //   font-size: 1rem;
  //   margin-top: 5px;
  // }
  // .selectr-tag {
  //   padding: 0px 20px 0px 5px;
  //   border-radius: 0.25rem;
  //   background-color: $dark;
  //   .selectr-tag-remove {
  //     right: 0px;
  //     &::before,
  //     &::after {
  //       width: 2px;
  //       height: 10px;
  //       top: 6px;
  //     }
  //   }
  // }
  // &::before {
  //   position: absolute;
  //   top: 50%;
  //   right: 16px;
  //   width: 16px;
  //   height: 16px;
  //   content: "";
  //   border: none;
  //   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23a69f88' viewBox='0 0 16 16'%3E%3Cpath d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  //   background-repeat: no-repeat;
  // }
  // }
  // .selectr-container {
  //   .selectr-input-container {
  //     background-color: $lighter;
  //     border-bottom: 1px solid $lightish;
  //   }
  //   > .selectr-options-container {
  //     border-color: $primary !important;
  //     border-radius: 0 0 0.25rem 0.25rem;
  //     overflow: hidden;
  //     z-index: 10002;
  //     box-shadow: 0 0 0 0.25rem rgba($primary, 0.25);
  //   }
  //   &.inverted > .selectr-options-container {
  //     border-radius: 0.25rem 0.25rem 0 0;
  //   }
  // }
  // &.scroll-for-more .selectr-container > .selectr-options-container:after {
  //   content: "scroll for more options";
  //   background-color: $lighter;
  //   border-top: 1px solid $lightish;
  //   color: $mid;
  //   display: block;
  //   text-align: center;
  //   font-size: small;
  //   padding: 0.25rem;
  // }
  // .selectr-options {
  //   max-height: calc((1rem * 1.5 + 10px) * 7.5);
  // }
  // .selectr-option {
  //   min-height: calc(1rem * 1.5 + 10px);
  //   &.active {
  //     background-color: $gray-400;
  //   }
  //   &.selected {
  //     background-color: $primary;
  //     color: $white;
  //   }
  //   &.disabled {
  //     display: none;
  //   }
  //   &.readonly {
  //     pointer-events: none;
  //     color: $mid;
  //   }
  // }
  // .selectr-input {
  //   outline: none;
  //   &::-webkit-search-decoration,
  //   &::-webkit-search-cancel-button,
  //   &::-webkit-search-results-button,
  //   &::-webkit-search-results-decoration {
  //     display: none;
  //   }
  // }
  // .selectr-container.open .selectr-selected::before {
  //   border: none;
  //   transform: rotate(180deg) translate3d(0px, 50%, 0px);
  // }
  // .selectr-clear {
  //   background: $white;
  //   right: 12px;
  //   width: 24px;
  //   height: 24px;
  // }
  // .selectr-clear::before,
  // .selectr-clear::after {
  //   top: 5px;
  //   left: 12px;
  //   width: 1px;
  //   height: 16px;
  //   content: "";
  //   background-color: $gray-400;
  // }
}

.dotted-underline {
  border-bottom: 1px dotted colors.$gray-400;
}

// Search input fields
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23#{string.slice(#{colors.$gray-400}, 2)}' viewBox='0 0 16 16'%3E%3Cpath d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}

input.datalist {
  background: colors.$white
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23#{string.slice(#{colors.$gray-400}, 2)}' viewBox='0 0 16 16'%3E%3Cpath d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E")
    calc(100% - 0.75em) 50% no-repeat;
  &::-webkit-list-button,
  &::-webkit-calendar-picker-indicator {
    opacity: 0;
    // width: 32px;
  }
}

input[type="date"],
input[type="time"],
input[type="datetime-local"] {
  font-size: 1.2rem;
  &::-webkit-inner-spin-button {
    display: none;
  }
  &::-webkit-calendar-picker-indicator {
    opacity: 0;
    padding: 50px 0px;
  }
  &.empty {
    color: colors.$gray-400;
  }
}
input[type="date"],
input[type="datetime-local"] {
  background: colors.$white
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23#{string.slice(#{colors.$gray-400}, 2)}' viewBox='0 0 16 16'%3E%3Cpath d='M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z'/%3E%3Cpath d='M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z'/%3E%3C/svg%3E")
    calc(100% - 0.75em) 50% no-repeat;
}
input[type="time"] {
  background: colors.$white
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23#{string.slice(#{colors.$gray-400}, 2)}' viewBox='0 0 16 16'%3E%3Cpath d='M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z'/%3E%3Cpath d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z'/%3E%3C/svg%3E")
    calc(100% - 0.75em) 50% no-repeat;
}

.filter-container {
  input {
    border-top-left-radius: 17px !important;
    border-bottom-left-radius: 17px !important;
    z-index: 1;
    padding-left: 2.5em !important;
  }
  > svg {
    z-index: 3;
  }
  button {
    border-top-right-radius: 17px !important;
    border-bottom-right-radius: 17px !important;
  }
}

// Multi-select
select[multiple].bg-secondary {
  background: colors.$gray-700 !important;
  option {
    padding: 0.4rem 1rem 0.4rem 2.5rem;
    background-color: colors.$gray-700;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23#{string.slice(#{colors.$gray-400}, 2)}' viewBox='0 0 16 16'%3E%3Cpath d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/%3E%3C/svg%3E");
    background-position: 0.75rem 50%;
    background-repeat: no-repeat, repeat;
    position: relative;
    // &:before {
    //   content: "";
    //   background: red;
    //   position: absolute;
    //   width: 16px;
    //   height: 16px;
    //   border-radius: 50%;
    //   border: 1px solid $gray-400;
    //   top: 50%;
    //   margin-top: -8px;
    //   left: 0.75rem;
    // }
    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23#{string.slice(#{colors.$gray-400}, 2)}' viewBox='0 0 16 16'%3E%3Cpath d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/%3E%3C/svg%3E"),
        linear-gradient(colors.$gray-600, colors.$gray-600);
      color: colors.$white !important;
    }
    &:checked {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23#{string.slice(#{colors.$gray-800}, 2)}' viewBox='0 0 16 16'%3E%3Cpath d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z'/%3E%3C/svg%3E"),
        linear-gradient(colors.$mid, colors.$mid);
    }
    &:active {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23#{string.slice(#{colors.$gray-800}, 2)}' viewBox='0 0 16 16'%3E%3Cpath d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z'/%3E%3C/svg%3E"),
        linear-gradient(colors.$brand, colors.$brand);
    }
  }
}

// Pagination
.page-link {
  min-width: 2.25rem;
  text-align: center;
}

// Offcanvas
.offcanvas-end {
  width: calc(320px - var(--sidebar-xs-width)) !important;
}

// Set dimensions
.s8x8 {
  width: 8px;
  height: 8px;
}
.s10x10 {
  width: 10px;
  height: 10px;
}
.s16x16 {
  width: 16px;
  height: 16px;
}
.s20x20 {
  width: 20px;
  height: 20px;
}
.s22x22 {
  width: 22px;
  height: 22px;
}
.s24x24 {
  width: 24px;
  height: 24px;
}
.s30x30 {
  width: 30px;
  height: 30px;
}
.s32x32 {
  width: 32px;
  height: 32px;
}
.s40x40 {
  width: 40px;
  height: 40px;
}
.s50x50 {
  width: 50px;
  height: 50px;
}
.s60x60 {
  width: 60px;
  height: 60px;
}
.s84x84 {
  width: 84px;
  height: 84px;
}
.s90x90 {
  width: 90px;
  height: 90px;
}
.w-1 {
  width: 1%;
}
.w-20 {
  width: 20%;
}
.w-60 {
  width: 60%;
}
.w-1px {
  width: 1px;
}
.w-30px {
  width: 30px;
}
.w-40px {
  width: 40px;
}
.w-105px {
  width: 105px;
}
.w-200px {
  width: 200px;
}
.w-300px {
  width: 300px;
}
.h-24px {
  height: 24px;
}
.h-60px {
  height: 60px;
}
.h-87px {
  height: 87px;
}
.h-100px {
  height: 100px !important;
}
.h-150px {
  height: 150px !important;
}
.h-200px {
  height: 200px !important;
}
.h-300px {
  height: 300px !important;
}
.min-w-60px {
  min-width: 60px;
}
.min-w-80px {
  min-width: 80px;
}
.min-w-280px {
  min-width: 280px;
}
.min-w-600px {
  min-width: 600px;
}
.max-w-105px {
  max-width: 105px;
}
.max-w-200px {
  max-width: 200px;
}
.max-w-400px {
  max-width: 400px;
}
.max-h-34px {
  max-height: 34px;
}
.max-h-50px {
  max-height: 50px;
}
.max-h-80px {
  max-height: 80px;
}
.max-h-145px {
  max-height: 145px;
}
.max-h-200px {
  max-height: 200px;
}
.max-h-240px {
  max-height: 240px;
}
.min-h-3em {
  min-height: 3em;
}
.min-h-90px {
  min-height: 90px !important;
}
.min-h-130px {
  min-height: 130px !important;
}

.z-9 {
  z-index: 9;
}
.mt-n8px {
  margin-top: -8px;
}
.mt-1px {
  margin-top: 1px;
}
.mb-2px {
  margin-bottom: 2px;
}

.rotate-1-deg {
  transform: rotate(1deg);
}
.rotate-2-deg {
  transform: rotate(2deg);
}
.rotate-3-deg {
  transform: rotate(3deg);
}

.rotate-neg2-deg {
  transform: rotate(-2deg);
}

.dotted-line {
  margin-left: 30%;
  border-left: 3px dotted;
}

.color-preview {
  z-index: 2;
  bottom: calc(0% + 15px);
}

.cursor-pointer {
  cursor: pointer;
}

.font-fixed {
  font-family: "Courier New", monospace;
}

.bg-transparent {
  background-color: transparent !important;
}

.very-unhappy,
.resolution-1 {
  background-color: colors.$very-unhappy;
  color: #831200;
}
.unhappy {
  background-color: colors.$unhappy;
  color: #a44f00;
}
.neutral,
.resolution-2 {
  background-color: colors.$neutral;
  color: #917b00;
}
.happy {
  background-color: colors.$happy;
  color: #639100;
}
.very-happy,
.resolution-3 {
  background-color: colors.$very-happy;
  color: #3c8000;
}

.text-my-aged-care {
  color: #4d2563;
}

.bg-positive-dull {
  background-color: #d0ecb2;
}
.bg-negative-dull {
  background-color: #472f2d;
}

.bg-pending {
  background-color: #ffb080;
}
.bg-new {
  background-color: #ff8941;
}
.bg-open {
  background-color: #ff6101;
}
.bg-closed {
  background-color: #747676;
}

.bg-negative-1 {
  background-color: #007dff;
}
.bg-negative-2 {
  background-color: #3397ff;
}
.bg-negative-3 {
  background-color: #66b1ff;
}
.bg-negative-4 {
  background-color: #99cbff;
}
.bg-negative-5 {
  background-color: #cce5ff;
}

.form-control-file {
  position: relative;
  color: colors.$secondary;
  &::before {
    content: "Choose file";
    position: absolute;
    z-index: 2;
    display: flex;
    background-color: colors.$primary;
    color: colors.$white;
    top: 0px;
    bottom: 0px;
    left: 0px;
    padding: 0px 1rem;
    align-items: center;
  }
  &:hover::before {
    background-color: shade-color(colors.$primary, 10%);
  }
  &.multiple::before {
    content: "Choose files";
  }
}

#feedbackCarousel {
  .carousel-inner {
    overflow: initial !important;
    overflow-y: visible;
    overflow-x: hidden;
  }
  .card {
    --min-height: 630px;
    min-height: var(--min-height);
    > .d-flex.flex-column {
      min-height: calc(var(--min-height) - 2rem);
    }
  }
  .feedback-areas,
  .feedback-happiness {
    svg {
      width: 50px;
      height: 50px;
    }
    button {
      transition: transform 200ms linear;
      &:hover {
        transform: scale(1.1);
      }
      &:active,
      &.active {
        transform: scale(1.1);
      }
    }
  }
  .feedback-areas button {
    &:active,
    &.active {
      background-color: shade-color(colors.$brand, 20%);
      box-shadow: 0 0 0 0.25rem rgba(colors.$brand, 0.2);
    }
  }
  .feedback-happiness button {
    &.very-unhappy {
      &:active,
      &.active {
        background-color: shade-color(colors.$very-unhappy, 20%);
        box-shadow: 0 0 0 0.25rem rgba(colors.$very-unhappy, 0.2);
      }
    }
    &.unhappy {
      &:active,
      &.active {
        background-color: shade-color(colors.$unhappy, 20%);
        box-shadow: 0 0 0 0.25rem rgba(colors.$unhappy, 0.2);
      }
    }
    &.neutral {
      &:active,
      &.active {
        background-color: shade-color(colors.$neutral, 20%);
        box-shadow: 0 0 0 0.25rem rgba(colors.$neutral, 0.2);
      }
    }
    &.happy {
      &:active,
      &.active {
        background-color: shade-color(colors.$happy, 20%);
        box-shadow: 0 0 0 0.25rem rgba(colors.$happy, 0.2);
      }
    }
    &.very-happy {
      &:active,
      &.active {
        background-color: shade-color(colors.$very-happy, 20%);
        box-shadow: 0 0 0 0.25rem rgba(colors.$very-happy, 0.2);
      }
    }
  }
}

.less-on-expanded {
  transition: transform 300ms linear;
}
.less-on-expand[aria-expanded="true"] {
  & > .more {
    display: none !important;
  }
  & > .less {
    display: inline !important;
  }
  svg:not(.do-not-spin) {
    transform: rotate(180deg);
  }
}
.less-on-expand[aria-expanded="false"] {
  & > .more {
    display: inline !important;
  }
  & > .less {
    display: none !important;
  }
  svg {
    transform: rotate(0deg);
  }
}
.hide-on-expand[aria-expanded="true"] {
  display: none !important;
}
.print-only {
  display: none !important;
}

.grow-wrap {
  display: grid;
  &::before {
    content: attr(data-replicated-value) " ";
    white-space: pre-wrap;
    visibility: hidden;
  }
  > textarea {
    resize: none;
    overflow: hidden;
    height: unset !important;
    min-height: calc(1.625rem + 0.325rem + (1.25rem * 2));
  }
  > textarea,
  &::before {
    padding-top: 1.625rem !important;
    padding-bottom: 0.325rem !important;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    line-height: 1.25 !important;
    font: inherit;
    grid-area: 1 / 1 / 2 / 2;
  }
}

// Chart ... disable text selection
.chart svg g text {
  pointer-events: none;
}
.chart.clickable svg > g > g > g rect {
  cursor: pointer;
}

@media print {
  @page {
    size: A4;
    margin: 0;
  }
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  body,
  html {
    min-height: 100vh;
    margin: 0;
    padding: 0 !important;
  }
  body {
    .print-only {
      display: block !important;
    }
    .no-print {
      display: none !important;
    }
    .no-break {
      margin: 0 !important;
      padding-top: 20px !important;
      page-break-inside: avoid;
      position: relative;
      display: inline-block !important;
      width: 100%;
    }
    .form-control {
      border: 0px;
    }
    header nav,
    header > div,
    nav.sidebar {
      display: none !important;
    }
    header.bg-brand {
      background-color: #ece8df !important;
      box-shadow: none !important;
    }
    main {
      position: relative;
      z-index: 2;
      .container-fluid {
        padding-left: 2em;
        padding-right: 2em;
      }
    }
    #print-background {
      position: fixed;
      width: 100%;
      min-height: 100%;
    }
    #print-logo {
      margin: 5px 5px;
      width: 62px;
      height: 62px;
    }
    #print-name {
      display: block !important;
      transform: rotate(90deg);
      transform-origin: 0% 50%;
      font-size: 40px;
      height: 72px;
      line-height: 68px !important;
      margin: 0 !important;
      position: relative;
      left: 36px;
      top: -30px;
    }
    #print-brand {
      position: absolute;
      bottom: 152px;
      left: 0px;
      img {
        position: relative;
        z-index: 2;
        border-radius: 50%;
        outline-style: solid;
        outline-color: #ece8df;
        outline-width: 5px;
        margin: 0px 5px;
      }
      span {
        display: block !important;
        transform: rotate(90deg);
        transform-origin: 0% 50%;
        font-size: 40px;
        height: 72px;
        width: 260px;
        line-height: 68px !important;
        margin: 0 !important;
        background: colors.$brand;
        padding: 0px 15px 0px 50px;
        position: relative;
        left: 36px;
        top: -72px;
        z-index: 1;
      }
    }
  }
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.fs-9 {
  font-size: 0.6rem;
}

.z-11 {
  z-index: 11;
}
.z-1002 {
  z-index: 1002;
}

.page-break-inside-avoid {
  page-break-inside: avoid;
}

.default-note {
  resize: none;
  transition: height 200ms linear 0s;
  padding-top: 0.5em !important;
}

// Fix to format the suggested actions in the chatbot
#collapseSuggestedActions li p {
  margin-bottom: 0px;
}

//
// Role colors
//
$role-colors: (
  "super_admin": colors.$blue,
  "admin": colors.$red,
  "manager": colors.$burnt-orange,
  "other_manager": colors.$yellow,
  "administrative_agent": colors.$purple,
  "agent": colors.$indigo,
  "user": colors.$green,
  "representative": colors.$teal,
  "other_visitor": colors.$cyan,
  "none": colors.$secondary,
) !default;

@each $key, $value in $role-colors {
  .bg-role-#{$key} {
    background-color: $value !important;
  }
  .text-role-#{$key} {
    color: $value !important;
  }
}

//
// Happiness colors
//
$happiness-colors: (
  "very-unhappy": colors.$very-unhappy,
  "unhappy": colors.$unhappy,
  "neutral": colors.$neutral,
  "happy": colors.$happy,
  "very-happy": colors.$very-happy,
);

$other-colors: (
  "brand-darker": colors.$brand-darker,
);

$button-colors: map.merge($other-colors, $happiness-colors);
@each $key, $value in $button-colors {
  .btn-#{$key} {
    background-color: $value !important;
    border-color: $value !important;
    color: white !important;
  }
  .btn-#{$key}.hover,
  .btn-#{$key}:hover {
    background-color: color.mix(black, $value, 15%) !important;
    border-color: color.mix(black, $value, 15%) !important;
    color: white !important;
  }

  .btn-#{$key}.active {
    box-shadow: var(--bs-btn-focus-box-shadow);
  }
  .btn-check:checked + .btn-#{$key},
  .btn-check:active + .btn-#{$key},
  .btn-#{$key}:active,
  .btn-#{$key}.active,
  .btn-#{$key}.show {
    background-color: color.mix(black, $value, 15%) !important;
  }
}

.visit-checkbox {
  width: 2rem;
  height: 2rem;
  margin-right: 0.75rem;
  &:checked + label {
    border-color: colors.$success !important;
  }
}

@each $key, $value in $happiness-colors {
  .bg-#{$key} {
    background-color: $value !important;
  }
  .text-#{$key} {
    color: $value !important;
  }
  .fill-#{$key} {
    fill: $value !important;
  }
}

//
// Miscelaneous Boostrap tralted styles
//
.bg-transparent {
  background-image: linear-gradient(45deg, #f8f9fa 25%, transparent 25%), linear-gradient(135deg, #f8f9fa 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #f8f9fa 75%), linear-gradient(135deg, transparent 75%, #f8f9fa 75%);
  background-size: 16px 16px;
  background-position: 0 0, 8px 0, 8px -8px, 0px 8px;
}
@media (min-width: 576px) {
  .text-sm-nowrap {
    white-space: nowrap;
  }
}

.link-hover-primary:hover {
  color: colors.$primary !important;
}

legend {
  float: none !important;
}

.page-link {
  color: colors.$brand !important;
}
.page-item.active .page-link {
  color: colors.$white !important;
}

.dashed {
  text-decoration-line: underline;
  text-decoration-style: dashed;
  cursor: help;
}
.signature-label {
  border-top: 1px dotted colors.$mid !important;
  opacity: 0.75;
}

.limited-wrap > * {
  flex: 0 0 calc(100% / 10);
  max-width: calc(100% / 10);
}

@media (min-width: 768px) {
  .limited-wrap > * {
    flex: 0 0 calc(100% / 15);
    max-width: calc(100% / 15);
  }
}
